import axios from 'axios';
import getExchangeRates from '../../../api/exchange-rates';
import { sleep, valueExists } from '../../../../utils/common';
import { filterTypes } from '../../../../components/filters/ConsolidatedTransactionsFilters';
import { addMisttrackRiskToTxs } from '../../../../utils/misttrack';
import { axiosErrorHandler } from '../../../api/axios';
import { toast } from 'react-hot-toast';
import { handleQuickRiskError } from '../../../../utils/quick-risk';


const getSolTxsLoader = ({
  address,
  accountId,
  withMisttrackRisks = true,
  currency = 'usd',
  pageSize = 50,
  apiUrl,
  userJwt,
  fromAddress = undefined,
  toAddress = undefined,
  fromToCombinator = { allHaveToMatch: false, orderMatters: true },
  externalApiKey = undefined,  // Helius API key, but general name to conform to loaders interface (virtual)
}) => {
  let oldestFetchedSignature = undefined;
  let nextPageExists = true;
  const solCurrencyPair = `sol/${currency.toLowerCase()}`;
  const usdtCurrencyPair = `usdt/${currency.toLowerCase()}`;
  const exchangeRates = {};

  const loadNextPage = async (filters) => {
    if (Object.keys(exchangeRates).length === 0) {
      const rates = await getExchangeRates([solCurrencyPair, usdtCurrencyPair], apiUrl, userJwt);
      exchangeRates['sol'] = rates?.[solCurrencyPair] ?? 0;
      exchangeRates['usdt'] = rates?.[usdtCurrencyPair] ?? 0;
    }

    if (filters) {
      const {
        accountsFilterParam,
        digitalAssetsFilterParam,
      } = filters;

      if (valueExists(accountsFilterParam)) {
        if (!accountsFilterParam.split(',').includes(accountId)) {
          nextPageExists = false;
          return {
            nextPage: [],
            loadError: undefined,
          };
        }
      }
      if (valueExists(digitalAssetsFilterParam)) {
        if (!digitalAssetsFilterParam.split(',').includes('SOL')) {
          nextPageExists = false;
          return {
            nextPage: [],
            loadError: undefined,
          };
        }
      }
    }

    const page = []; // Due to from/to filtering, multiple requests might be needed to fill a single page
    let remainingRequests = 10; // So that we don't run out of credits. This is 10 * 100 = 1000 txs
    const url = `https://api.helius.xyz/v0/addresses/${address}/transactions`;

    while (page.length < pageSize) {
      const params = {
        'api-key': externalApiKey,
        limit: 100,
      };
      if (valueExists(oldestFetchedSignature)) {
        params.before = oldestFetchedSignature;
      }

      let data = [];
      try {
        const response = await axios.get(
          url,
          { params }
        );
        data = response?.data ?? [];
      } catch (err) {
        // Ignore too many requests errors
        if (err?.response?.status !== 429) {
          toast.error(axiosErrorHandler(err, 'Error getting Solana transactions from explorer, data might be incomplete'));
        }
      }

      if (data.length < pageSize) {
        nextPageExists = false;
      }

      // Move cursor
      if (data.length > 0) {
        const oldestSignature = data.slice(-1)?.[0]?.signature;
        if (oldestSignature) {
          oldestFetchedSignature = oldestSignature;
        }
      }

      const accountTransactions = data
        .map(
          heliusTx => transformToAccountTx(heliusTx, address, accountId, exchangeRates, currency)
        )
        .filter( // Apply from / to filters
          accountTx => {
            const fromAddressSends = (!valueExists(fromAddress)) || accountTx.inputs.some(input => input.address.toLowerCase() === fromAddress.toLowerCase());
            const toAddressReceives = (!valueExists(toAddress)) || accountTx.outputs.some(output => output.address.toLowerCase() === toAddress.toLowerCase());
            const fromAddressReceives = (!valueExists(fromAddress)) || accountTx.outputs.some(output => output.address.toLowerCase() === fromAddress.toLowerCase());
            const toAddressSends = (!valueExists(toAddress)) || accountTx.inputs.some(input => input.address.toLowerCase() === toAddress.toLowerCase());

            let acceptThisTx = false;
            if (fromToCombinator.allHaveToMatch) {
              acceptThisTx = fromAddressSends && toAddressReceives;
            } else {
              acceptThisTx = fromAddressSends || toAddressReceives;
            }
            if (!fromToCombinator.orderMatters && !acceptThisTx) {
              if (fromToCombinator.allHaveToMatch) {
                acceptThisTx = fromAddressReceives && toAddressSends;
              } else {
                acceptThisTx = fromAddressReceives || toAddressSends;
              }
            }

            return acceptThisTx;
          }
        );

      for (const tx of accountTransactions) {
        if (page.length < pageSize) {
          page.push(tx);
        } else {
          oldestFetchedSignature = tx.signature;
          nextPageExists = true;
          break;
        }
      }

      remainingRequests -= 1;
      if (remainingRequests <= 0) {
        break;
      }
    }

    if (!withMisttrackRisks) {
      return {
        nextPage: page,
        loadError: undefined,
      };
    }

    try {
      const quickRiskResponse = await axios.get(
        '/v1/quick_risk',
        {
          baseURL: apiUrl,
          headers: {
            'Authorization': `Bearer ${userJwt}`,
          },
          params: {
            chain: 'sol',
            address: address,
            metrics: 'riskResult',
          },
        }
      );
      const riskDetail = quickRiskResponse?.data?.risk_result?.risk_detail ?? [];
      const txsWithCustomRisk = addMisttrackRiskToTxs(page, riskDetail);
      return {
        nextPage: txsWithCustomRisk,
        loadError: undefined,
      };
    } catch (err) {
      handleQuickRiskError(err);
      return {
        nextPage: [],
        loadError: undefined,
      };
    }
  };

  const hasNextPage = () => {
    return nextPageExists;
  };

  const reset = () => {
    oldestFetchedSignature = undefined;
    nextPageExists = true;
  };

  const getAllowedFilters = () => {
    return [filterTypes.ACCOUNTS, filterTypes.ASSETS];
  };

  return { loadNextPage, hasNextPage, reset, getAllowedFilters };
};

const getCreatedAt = (transaction) => {
  const epochTime = transaction.timestamp;
  return new Date(epochTime * 1000);
};

const getSimpleTransactionFee = (accountTransaction, fee = null, currency) => {
  if (fee === null) {
    let sumInput = accountTransaction.inputs.reduce((sum, txInput) => sum + txInput.value, 0);
    let sumOutput = accountTransaction.outputs.reduce((sum, txOutput) => sum + txOutput.value, 0);
    fee = sumInput - sumOutput;
  }
  return getSimpleNonNegativeCurrencyValue(fee, currency);
};

const getSimpleNonNegativeCurrencyValue = (value, currency) => {
  return [{
    value: value,
    currency: currency,
    currencyName: currency,
    currencySymbol: currency,
  }];
};

const getTransactionTotal = (accountTransaction) => {
  let total = [];
  accountTransaction.inputs.forEach(txInput => {
    total = sumMovement(total, txInput, true);
  });
  return total;
};

const getTransactionAmount = (accountTransaction) => {
  let sumOutputsOwned = [];
  accountTransaction.outputs.forEach(txOutput => {
    if (txOutput.owned) {
      sumOutputsOwned = sumMovement(sumOutputsOwned, txOutput, false);
    }
  });

  let sumInputsOwned = [];
  accountTransaction.inputs.forEach(txInput => {
    if (txInput.owned) {
      sumInputsOwned = sumMovement(sumInputsOwned, txInput, false);
    }
  });

  return subCurrencyValues(sumOutputsOwned, sumInputsOwned);
};

const sumMovement = (currencyValues, movement, isTotal) => {
  for (let currencyValue of currencyValues) {
    if (currencyValue.currency === movement.currency) {
      let isToken = currencyValue.currency.split(':').length >= 2;
      if (!isTotal || !isToken) {
        currencyValue.value += movement.value;
      }
      return currencyValues;
    }
  }
  currencyValues.push({
    value: movement.value,
    currency: movement.currency,
    currencyName: movement.currency_name,
    currencySymbol: movement.currency_symbol,
  });
  return currencyValues;
};

const subCurrencyValues = (nonNegativeCurrencyValues1, nonNegativeCurrencyValues2) => {
  const currencyValues1 = nonNegativeCurrencyValues1.map(value => ({ ...value }));
  const currencyValues2 = nonNegativeCurrencyValues2.map(value => ({ ...value }));

  currencyValues2.forEach(currencyValue2 => {
    const match = currencyValues1.find(currencyValue1 => currencyValue1.currency === currencyValue2.currency);
    if (match) {
      match.value -= currencyValue2.value;
    } else {
      currencyValues1.push({ ...currencyValue2, value: -currencyValue2.value });
    }
  });

  return currencyValues1;
};

const transformToAccountTx = (heliusTx, originatorAddress, accountId, exchangeRates, fiatCurrency) => {
  const SOL_CURRENCY_FACTOR = 1000000000;
  const currency = 'SOL';

  let type = 'transfer';
  const originatorPaysFee = heliusTx.feePayer.toLowerCase() === originatorAddress.toLowerCase();
  const toOriginatorOutputExists = [...(heliusTx.nativeTransfers ?? []), ...(heliusTx.tokenTransfers ?? [])].some(
    transferInfo => transferInfo?.toUserAccount.toLowerCase() === originatorAddress.toLowerCase()
  );
  if (originatorPaysFee && !toOriginatorOutputExists) {
    type = 'crypto withdrawal';
  } else if (!originatorPaysFee && toOriginatorOutputExists) {
    type = 'crypto deposit';
  }

  const accountTransaction = {
    created_at: getCreatedAt(heliusTx),
    transaction_id: heliusTx.signature,
    account_id: accountId,
    type: type,
    inputs: [],
    outputs: [],
    categories: [],
    hideValue: false,
  };

  // SOL transfers
  heliusTx.nativeTransfers.forEach(solTransfer => {
    const inputAddress = solTransfer.fromUserAccount;
    const amount = solTransfer.amount / SOL_CURRENCY_FACTOR;

    const inputMovement = {
      address: inputAddress,
      currency: currency,
      currency_name: currency,
      currency_symbol: currency,
      value: amount,
      fee: false,
      owned: inputAddress.toLowerCase() === originatorAddress.toLowerCase(),
    };

    const outputAddress = solTransfer.toUserAccount;
    const outputMovement = {
      address: outputAddress,
      currency: currency,
      currency_name: currency,
      currency_symbol: currency,
      value: amount,
      fee: false,
      owned: outputAddress.toLowerCase() === originatorAddress.toLowerCase(),
    };

    if (exchangeRates[currency.toLowerCase()] !== undefined) {
      inputMovement.converted_value = amount * exchangeRates[currency.toLowerCase()];
      outputMovement.converted_value = amount * exchangeRates[currency.toLowerCase()];
      inputMovement.converted_currency = fiatCurrency;
      outputMovement.converted_currency = fiatCurrency;
    }

    accountTransaction.inputs.push(inputMovement);
    accountTransaction.outputs.push(outputMovement);
  });

  // SPL transfers
  heliusTx.tokenTransfers.forEach(tokenTransfer => {
    const fromAddress = tokenTransfer.fromUserAccount;
    const toAddress = tokenTransfer.toUserAccount;
    const currencyString = `${currency}:${tokenTransfer.mint}`;
    const amount = tokenTransfer.tokenAmount;

    const splInput = {
      address: fromAddress,
      currency: currencyString,
      currency_name: currencyString,
      currency_symbol: currencyString,
      value: amount,
      fee: false,
      owned: fromAddress.toLowerCase() === originatorAddress.toLowerCase(),
    };

    const splOutput = {
      address: toAddress,
      currency: currencyString,
      currency_name: currencyString,
      currency_symbol: currencyString,
      value: amount,
      fee: false,
      owned: toAddress.toLowerCase() === originatorAddress.toLowerCase(),
    };

    if (exchangeRates[currency.toLowerCase()] !== undefined) {
      splInput.converted_value = amount * exchangeRates[currency.toLowerCase()];
      splOutput.converted_value = amount * exchangeRates[currency.toLowerCase()];
      splInput.converted_currency = fiatCurrency;
      splOutput.converted_currency = fiatCurrency;
    }

    accountTransaction.inputs.push(splInput);
    accountTransaction.outputs.push(splOutput);
  });

  // Add fee
  const fee = heliusTx.fee / SOL_CURRENCY_FACTOR;
  const feePayerAddress = heliusTx.feePayer;

  const feeInput = {
    address: feePayerAddress,
    currency: currency,
    currency_name: currency,
    currency_symbol: currency,
    value: fee,
    fee: false,
    owned: feePayerAddress.toLowerCase() === originatorAddress.toLowerCase(),
  };

  const feeOutput = {
    address: '',
    currency: currency,
    currency_name: currency,
    currency_symbol: currency,
    value: fee,
    fee: true,
    owned: false,
  };

  if (exchangeRates[currency.toLowerCase()] !== undefined) {
    feeInput.converted_value = fee * exchangeRates[currency.toLowerCase()];
    feeOutput.converted_value = fee * exchangeRates[currency.toLowerCase()];
    feeInput.converted_currency = fiatCurrency;
    feeOutput.converted_currency = fiatCurrency;
  }

  accountTransaction.inputs.push(feeInput);
  accountTransaction.outputs.push(feeOutput);

  accountTransaction.fee = getSimpleTransactionFee(accountTransaction, fee);
  accountTransaction.total = getTransactionTotal(accountTransaction);
  accountTransaction.amount = getTransactionAmount(accountTransaction);

  return accountTransaction;
};

export default getSolTxsLoader;
